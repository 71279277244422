/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Plotly from 'react-plotly.js';
import { UnitHelper } from '@/helpers/UnitHelper';
import { htmlTableRenderer } from '@/helpers/HTMLTableHelper';
import { colors } from '@/designSystem/colors';
import { accessibilityUsageLabel } from '@/helpers/AccessibilityHelper';
import { Usage } from '@/molecules/NewUsage';
import { LinkButton } from '@/designSystem/components';
import { sentEvent } from '@/helpers/GAHelper';
import PATHS from '@/types/navigationPaths';
import { useMediaQuery } from 'react-responsive';
import { sizes } from '@/assets/styles/media';
import { PerEventDisclaimer, perEventDisclaimer } from '@/helpers/PerEventDisclaimer';
import { shouldDisplayCO2FeatureChanges, suffixForCO2Languages } from '@/helpers/Edition';
import { IDRQuickViewViewProps } from './index.types';
import {
  Wrapper,
  GraphContainer,
  UsageContainer,
  ChartContainer,
  GraphDescriptionContainer,
  UsageWrapper,
  Disclaimer,
} from './index.styles';
import { PlotProps } from '../DRDetailView/index.view';

const DRQuickViewView = ({ item, moneyEarnedInProgram }: IDRQuickViewViewProps) => {
  const { t, i18n } = useTranslation('common');
  const isMobileWindowSize = useMediaQuery({ maxWidth: sizes.lg });

  // Type guard to check if item is of type DrHistoryItem
  if (!item || !('actualRequestUsage' in item)) {
    return null; // or handle the case where item is not of the expected type
  }

  // Type guard to check if item is of type DetailGrpahItem or DrHistoryItem
  if (!('quickViewData' in item)) {
    return null; // or handle the case where item is not of the expected type
  }

  const {
    actualRequestUsage,
    quickViewData: { hadReduced, typicalDayUsage, moneyEarned, changeInUsage },
  } = item;

  const { disclaimerNamespace, disclaimerSymbol } = perEventDisclaimer({
    perEventDisclaimer: item?.quickViewData?.moneyEarnedDisclaimer || PerEventDisclaimer.None,
  });
  const disclaimer = t(disclaimerNamespace);

  const renderTick = (value: number, color: string, name: string) => {
    return `<span style="color: ${colors.blackGrey}; font-family: Lato; line-height: 18px; font-weight: 500; font-size: 15px">${name}</span>`;
  };

  const data: PlotProps['data'] = [
    {
      name: t(
        `screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graph.legend.typical${suffixForCO2Languages}`,
      ),
      type: 'bar',
      x: [0],
      y: [typicalDayUsage],
      hovertemplate: `%{y:.2f}Wh`,
      marker: {
        color: colors.grey,
      },
      width: [0.3],
      text: `<span style="color: ${
        colors.grey
      }; font-family: Lato; line-height: 18px; font-weight: 700; font-size: 15px"><b>${UnitHelper.formatEnergyValue(
        typicalDayUsage,
        2,
      )}</b></span>`,
      textposition: 'outside',
    },
    {
      name: t(
        `screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graph.legend.energyEvent${suffixForCO2Languages}`,
      ),
      type: 'bar',
      x: [1],
      y: [actualRequestUsage],
      hovertemplate: `%{y:.2f}Wh`,
      marker: {
        color: colors.accent,
      },
      width: [0.3],
      text: `<span style="color: ${
        colors.accent
      }; font-family: Lato; line-height: 18px; font-weight: 700; font-size: 15px">${UnitHelper.formatEnergyValue(
        actualRequestUsage,
        2,
      )}</span>`,
      textposition: 'outside',
    },
  ];

  /**
   * rangeMaxValue?
   * The 'Auto range' or 'margin' options do not consider the size of 'data.text'.
   * Therefore, if 'Auto Range' is turned off, a buffer is added to the range by increasing the determined
   * maximum value between the two data point by 15%
   * (e.g. if (typicalDayUsage > actualRequestUsage) return typicalDayUsage').
   */
  const rangeMaxValue =
    (typicalDayUsage > actualRequestUsage ? typicalDayUsage : actualRequestUsage) +
    ((typicalDayUsage > actualRequestUsage ? typicalDayUsage : actualRequestUsage) / 100) * 15;

  const layout: PlotProps['layout'] = {
    dragmode: false,
    margin: {
      l: 20,
      r: 20,
      b: 30,
      t: 15,
      pad: 10,
    },
    paper_bgcolor: 'transparent',
    plot_bgcolor: 'transparent',
    xaxis: {
      ticktext: [
        renderTick(
          typicalDayUsage,
          colors.grey,
          t(
            `screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graph.typicalDay${suffixForCO2Languages}`,
          ),
        ),
        renderTick(
          actualRequestUsage,
          colors.accent,
          t(
            `screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graph.legend.energyEvent${suffixForCO2Languages}`,
          ),
        ),
      ],
      tickvals: [0, 1],
      tickcolors: [colors.grey, colors.accent],
    },
    yaxis: {
      showticklabels: false,
      showgrid: false,
      zeroline: false,
      autorange: false,
      range: [0, rangeMaxValue],
    },
    showlegend: false,
  } as Partial<Plotly.Layout>;

  const config = { displayModeBar: false, responsive: true };
  const chartStyle = { width: '100%', height: 250 };

  const graphDescriptionTableHTML = htmlTableRenderer(
    t('screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graph.title'),
    [
      t(
        `screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graph.legend.typical${suffixForCO2Languages}`,
      ),
      t(
        `screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graph.legend.energyEvent${suffixForCO2Languages}`,
      ),
    ],
    // @ts-ignore
    [UnitHelper.formatEnergyValue(typicalDayUsage, 2), UnitHelper.formatEnergyValue(actualRequestUsage, 2)],
    '',
    i18n.language.toLocaleLowerCase(),
  );

  const graphDescriptionFile = new Blob([graphDescriptionTableHTML], { type: 'text/html' });
  const graphDescriptionURL = URL.createObjectURL(graphDescriptionFile);
  const handleGraphDescription = () => {
    const newWindow = window.open(graphDescriptionURL, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
    sentEvent('open_graph_description', PATHS.OPEN_GRAPH_DESCRIPTION);
  };

  const formattedEnergyValue = UnitHelper.formatEnergyValue(changeInUsage, 2);
  const energyValueColor = hadReduced ? colors.accent : colors.bad;

  const formattedMoneyEarned = UnitHelper.formatMoneyValue(moneyEarned);

  return (
    <Wrapper>
      <ChartContainer>
        <GraphContainer>
          <Plotly data={data} layout={layout} config={config} style={chartStyle} />
        </GraphContainer>
        <GraphDescriptionContainer>
          <LinkButton
            onClick={handleGraphDescription}
            title={t('common.generalButtons.graphDescription')}
            underline={false}
            ariaLabel={t('common.generalButtons.graphDescription')}
            role="link"
            padding="12px 6px"
            fontSize={isMobileWindowSize ? '17px' : '15px'}
          />
        </GraphDescriptionContainer>
      </ChartContainer>
      <div>
        <UsageWrapper moneyEarnedInProgram={moneyEarnedInProgram}>
          <UsageContainer>
            <Usage
              value={`${hadReduced ? '↓' : '↑'}${formattedEnergyValue}`}
              valueColor={energyValueColor}
              description={t(
                `screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graph.reduction${suffixForCO2Languages}`,
              )}
            />
          </UsageContainer>
          {moneyEarnedInProgram && !shouldDisplayCO2FeatureChanges && (
            <UsageContainer>
              <Usage
                value={`${formattedMoneyEarned}${disclaimerSymbol}`}
                valueColor={colors.accent}
                valueAriaLabel={accessibilityUsageLabel(formattedMoneyEarned)}
                description={t('screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graph.moneyEarned')}
              />
            </UsageContainer>
          )}
        </UsageWrapper>
        {!!disclaimer && <Disclaimer>{disclaimer}</Disclaimer>}
      </div>
    </Wrapper>
  );
};

export default DRQuickViewView;
