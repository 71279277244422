import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GBCStatus } from '@/types/userInfo';
import { sentEvent } from '@/helpers/GAHelper';
import PATHS from '@/types/navigationPaths';
import { useSites, useEvents, useEventsHistory, useEarningsHistory } from '@/hooks/index';
import { RootState } from '@/stores/index';
import contents from '@/assets/config/appInfo';
import EnergySavingIdeas from '@/organisms/EnergySavingIdeas';
import SavingsEventsHistory from '@/organisms/SavingsEventsHistory';
import GBCStatusComponent from '@/organisms/GBCStatus';
import ModalEnergyEvent from '@/organisms/ModalEnergyEvent';
import HistoryItemDetail from '@/organisms/HistoryItemDetail';
import DREndedEventPopup from '@/organisms/DREndedEventPopup/DREndedEventPopup';
import DRNoEvent from '@/organisms/DRNoEvent';
import DROnEvent from '@/organisms/DROnEvent';
import DRUpcomingEvent from '@/organisms/DRUpcomingEvent';
import { ReqEventNotified } from '@/apis/primary/types';
import { DrNearestEvent, EventStatusEnum, OptInModeEnum, PerformanceToDateEnum, UserStatusEnum } from '@/types/dr';
import { getProgramContext } from '@/helpers/ProgramStrategy';
import { images } from '@/assets/styles';
import { IconButton } from '@/designSystem/components/buttons';
import { ProgramName } from '@/types/enrollment';
import { HistoryTabs } from '@/screens/Main/SavingsEvents/SavingsEventsScreen/SavingsEventsScreen.types';
import DataOnlyMessage from '@/molecules/DataOnlyMessage';
import { getProgramNameFromSite } from '@/helpers/GetProgramName';
import {
  EventsContainer,
  MyEventsContainer,
  MyEventsTitle,
  ViewPortfolioContainer,
  ViewPortfolioTitle,
  ViewPortfolioTitleContainer,
  Wrapper,
} from './SavingsEventsScreen.styles';

export const setInitialEventHistoryYear = (
  seasonYears: string[],
  setSelectedSeasonIndex: React.Dispatch<React.SetStateAction<number>>,
) => {
  if (seasonYears.length > 0) {
    const numberedSeasonYears = seasonYears.map((yr) => Number(yr));
    const mostRecentSeasonYear = Math.max(...numberedSeasonYears);
    const mostRecentSeasonYearIndex = seasonYears.findIndex((yr) => Number(yr) === mostRecentSeasonYear);
    setSelectedSeasonIndex(mostRecentSeasonYearIndex);
  }
};

const SavingsEventsScreen = () => {
  const { t } = useTranslation('common');
  const {
    dr: {
      historyScreen: { selectedTab },
      history: {
        events: {
          data: { selectedEvent, seasons: eventsSeasons },
        },
        earnings: {
          data: { selectedEarningsEvent, seasons: earningsSeasons },
        },
      },
      events: {
        data: { nearestDrEvents, recentlyEndedEvent },
      },
    },
    sites: {
      data: { currentSite },
    },
    userInfo: {
      data: { userInfo },
    },
  } = useSelector((state: RootState) => state);
  const programName = getProgramNameFromSite(currentSite);
  document.title = `${t(`common:programs.${programName}.savingsEventsScreen.title`)} - ${contents.name}`;

  const [state, setState] = useState<{
    eventStatus: EventStatusEnum;
    nearestDrEvent: DrNearestEvent;
  }>({
    eventStatus: EventStatusEnum.NoEvent,
    nearestDrEvent: {
      eventTimeRange: '',
      length: '',
      end: '',
      canceled: false,
      userEventId: '',
      start: '',
      hasBeenNotified: true,
      userStatus: UserStatusEnum.DefaultEvent,
      programAttributes: {
        optInMode: OptInModeEnum.affirmative,
      },
    },
  });

  const [showEnergyEventModal, setShowEnergyEventModal] = useState(false);
  const [selectedHistoryTab, setSelectedHistoryTab] = useState<HistoryTabs>(HistoryTabs.QuickView);
  const performanceToDateRef = useRef<HTMLDivElement>(null);

  const unfilteredSeasonYears = [...Object.keys(eventsSeasons), ...Object.keys(earningsSeasons)].sort();
  const seasonYears = unfilteredSeasonYears.filter((year, index) => unfilteredSeasonYears.indexOf(year) === index);
  const [selectedSeasonIndex, setSelectedSeasonIndex] = useState(0);
  // the .getFullYear() is to show the current year while event history data is loading
  const selectedSeasonYear = seasonYears[selectedSeasonIndex] ?? new Date().getFullYear();

  const { fetchSites } = useSites();

  const { fetchDr, fetchEventNotifiedUpdate } = useEvents();
  const { fetchEventsHistoryRequest, fetchEventHistorySelectReset } = useEventsHistory();
  const { fetchEarningsHistoryRequest, fetchEarningsHistorySelectReset } = useEarningsHistory();

  useEffect(() => {
    if (userInfo.gbcStatus === GBCStatus.completed && !currentSite) {
      fetchSites(userInfo.siteName);
    }
  }, []);

  useEffect(() => {
    setInitialEventHistoryYear(seasonYears, setSelectedSeasonIndex);
  }, [seasonYears.length]);

  useEffect(() => {
    if (currentSite) {
      fetchDr(currentSite.id);
      fetchEventsHistoryRequest(currentSite.id);
      fetchEarningsHistoryRequest(currentSite.id);
    }
  }, [currentSite?.id]);

  const navigate = useNavigate();
  useEffect(() => {
    if (userInfo.isDeleted === true) {
      navigate(PATHS.SCREEN_SETTING_ACCOUNT.pathname);
    }
  });

  const selectedItem = selectedTab === PerformanceToDateEnum.reduction ? selectedEvent : selectedEarningsEvent;
  const isAuthenticated = userInfo.gbcStatus === GBCStatus.completed;

  const programContext = getProgramContext({ programName });
  const shouldDisplaySavingsIdeas = programContext?.getShouldDisplaySavingsIdeas();
  const shouldDisplayPortfolio = programContext?.getShouldDisplayPortfolio();

  const changeEnergyEventModalVisibility = () => {
    sentEvent('click', PATHS.HELP_ENERGY_EVENT);
    setShowEnergyEventModal(!showEnergyEventModal);
  };

  useEffect(() => {
    sentEvent('screen_view', {
      screen_name: 'Savings Event',
      screen_class: 'savings_event',
    });
  }, []);

  useEffect(() => {
    if (nearestDrEvents.length === 0) {
      return;
    }

    const nearestDrEvent = nearestDrEvents[0];
    if (
      nearestDrEvent.userEventId === state.nearestDrEvent.userEventId &&
      nearestDrEvent.hasBeenNotified === state.nearestDrEvent.hasBeenNotified
    ) {
      return;
    }

    setState({
      ...state,
      nearestDrEvent,
    });
  }, [nearestDrEvents]);

  useEffect(() => {
    if (state.nearestDrEvent.userEventId === '') {
      setState({ ...state, eventStatus: EventStatusEnum.NoEvent });
      return undefined;
    }

    setState({
      ...state,
      eventStatus: EventStatusEnum.UpcomingEvent,
    });

    const timer = setInterval(() => {
      if (new Date() >= new Date(state.nearestDrEvent.start)) {
        setState({
          ...state,
          eventStatus: EventStatusEnum.OnEvent,
        });
      }

      if (new Date() >= new Date(state.nearestDrEvent.end)) {
        setState({ ...state, eventStatus: EventStatusEnum.NoEvent });
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [state.nearestDrEvent.userEventId]);

  useEffect(() => {
    const nearestDrEvent = nearestDrEvents[0];
    if (nearestDrEvent.programAttributes) {
      setState({
        ...state,
        nearestDrEvent: {
          ...nearestDrEvent,
          programAttributes: {
            ...nearestDrEvent.programAttributes,
            optInMode: nearestDrEvent.programAttributes.optInMode,
          },
        },
      });
    } else {
      setState({
        ...state,
        nearestDrEvent,
      });
    }
  }, [nearestDrEvents]);

  const eventEndedClose = () => {
    const payload: ReqEventNotified = {
      userEventId: recentlyEndedEvent.pastUserEventId,
      recentlyEndedEventNotified: true,
    };
    if (currentSite) {
      fetchEventNotifiedUpdate(currentSite.id, payload);
    }
  };

  const handleSeasonChange = (value: number | ((prevVar: number) => number)) => {
    setSelectedSeasonIndex(value);
    // fetchEventEarningsSelectInit();
    fetchEventHistorySelectReset();
    fetchEarningsHistorySelectReset();
  };

  return (
    <Wrapper>
      {isAuthenticated ? (
        <>
          <MyEventsContainer>
            <MyEventsTitle>{t(`screens.main.savingsEvents.savingsEventsScreen.myEvents`)}</MyEventsTitle>
            {programName === ProgramName.DATA_ONLY ? (
              <DataOnlyMessage />
            ) : (
              <EventsContainer>
                {/* {shouldDisplayProgramInformationButton && <ProgramInformation />} */}
                {state.eventStatus === EventStatusEnum.OnEvent && (
                  <DROnEvent index={0} userStatus={state.nearestDrEvent.userStatus} programName={programName} />
                )}
                {state.eventStatus === EventStatusEnum.UpcomingEvent && nearestDrEvents.length > 0 && (
                  <DRUpcomingEvent
                    event={nearestDrEvents[0]}
                    multipleDrEvent={
                      state.eventStatus === EventStatusEnum.UpcomingEvent &&
                      nearestDrEvents.length > 1 &&
                      nearestDrEvents[1]
                    }
                  />
                )}
                {state.eventStatus === EventStatusEnum.NoEvent && <DRNoEvent />}
              </EventsContainer>
            )}
          </MyEventsContainer>
          {shouldDisplayPortfolio && (
            <ViewPortfolioContainer>
              <ViewPortfolioTitleContainer>
                <img src={images.iconViewPortfolio.image} alt={images.iconViewPortfolio.text} />
                <ViewPortfolioTitle>{t('screens.main.savingsEvents.portfolioScreen.viewPortfolio')}</ViewPortfolioTitle>
              </ViewPortfolioTitleContainer>
              <IconButton
                onClick={() => navigate(PATHS.SCREEN_PORTFOLIO.pathname)}
                image={{
                  active: {
                    src: images.textIconButtonArrow.image,
                    alt: images.textIconButtonArrow.text,
                  },
                }}
                padding="10px 13px"
              />
            </ViewPortfolioContainer>
          )}
          <SavingsEventsHistory
            selectedSeasonIndex={selectedSeasonIndex}
            setSelectedSeasonIndex={handleSeasonChange}
            seasonYears={seasonYears}
            selectedSeasonYear={selectedSeasonYear}
            performanceToDateRef={performanceToDateRef}
            eventsSeasons={eventsSeasons}
            earningsSeasons={earningsSeasons}
          >
            <HistoryItemDetail
              item={selectedItem}
              tabLinkSelected={selectedHistoryTab}
              onTabClick={setSelectedHistoryTab}
              onHelp={changeEnergyEventModalVisibility}
            />
          </SavingsEventsHistory>
          {recentlyEndedEvent.eventParticipatedRecentlyEnded && !recentlyEndedEvent.recentlyEndedEventNotified && (
            <DREndedEventPopup
              onPress={eventEndedClose}
              eventEndedStart={new Date(recentlyEndedEvent.start)}
              eventEndedEnd={new Date(recentlyEndedEvent.end)}
              programName={programName}
            />
          )}
          {shouldDisplaySavingsIdeas && <EnergySavingIdeas />}
        </>
      ) : (
        <GBCStatusComponent />
      )}
      <ModalEnergyEvent show={showEnergyEventModal} onClose={changeEnergyEventModalVisibility} />
    </Wrapper>
  );
};

export default SavingsEventsScreen;
