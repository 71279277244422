import styled, { css } from 'styled-components';
import { typography } from '@/designSystem/typography';
import { colors } from '@/designSystem/colors';
import { media } from '@/assets/styles';

export const WrapperVPP = styled.div`
  background-color: ${colors.white};
  border-radius: 8px;
  margin-top: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  flex: 1;
  padding: 15px 0 5px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${media.lg`
    flex-direction: row;
    padding: 15px;
  `}

  ${media.xl`
    gap: 40px;
    justify-content: unset;
  `}
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const EarningsTitleConainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 10px;
  min-width: 132px;
`;

export const EarningsTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export const DateContainer = styled.div`
  display: flex;
  gap: 5px;
`;

export const Img = styled.img<{ width?: string; height?: string }>`
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `}
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
`;
export const ValueContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: start;
  gap: 2px;
  ${media.lg`  
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
  `}
`;

export const EarningsRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding-bottom: 5px;
`;

export const Date = styled.p`
  ${typography.xxs};
  line-height: 16px;
  font-weight: 400;
  color: ${colors.grey};
`;

export const Incentive = styled.p`
  ${typography.xs};
  font-weight: 800;
  line-height: 18px;
  color: ${colors.text};
`;
export const Earnings = styled.p`
  ${typography.xs};
  font-weight: 800;
  line-height: 18px;
  color: ${colors.accent};
`;
export const EarningsDescription = styled.span`
  ${typography.xs};
  font-weight: 400;
  line-height: 18px;
  color: ${colors.accent};
`;
export const Description = styled.span`
  ${typography.xs};
  font-weight: 400;
  line-height: 18px;
  color: ${colors.text};
`;

export const PerEventDisclaimer = styled.span`
  display: block;
  ${typography.xxs};
  font-weight: 400;
  line-height: 18px;
  color: ${colors.grey};
  text-align: center;
  margin-bottom: 15px;
`;

export const Balance = styled.p`
  ${typography.xs};
  line-height: 18px;
  font-weight: 800;
  color: ${colors.text};
`;

interface ButtonProps {
  padding?: string;
  warning?: boolean;
  iconFirst?: boolean;
  gap?: string;
  reverse?: boolean;
  selected?: boolean;
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  background-color: transparent;
  border-radius: 8px;
  background-color: ${colors.white};
  gap: 20px;

  ${media.lg`
    width: unset;
    background-color: transparent;
    gap: 0;
  `};
  padding: ${(props) => props.padding};
  gap: ${(props) => props.gap};
  ${(props) =>
    props.reverse &&
    css`
      flex-direction: row-reverse;
    `}
  &:hover {
    background-color: ${(props) => (props.warning ? colors.badFocus : colors.hoverFocus)};
  }
  &:focus {
    background-color: ${(props) => (props.warning ? colors.badFocus : colors.hoverFocus)};
    box-shadow: ${(props) =>
      props.warning ? '0px 0px 0px 4px rgba(215, 2, 26, 0.2);' : '0px 0px 0px 4px rgba(69, 129, 29, 0.2)'};
  }
  &:disabled {
    cursor: default;
    color: ${colors.grey};
  }
  &:disabled:hover {
    background-color: transparent;
  }
  ${(props) =>
    props.selected &&
    css`
      background-color: ${colors.hoverFocus};
    `}
`;

export const ImgArrow = styled.img<{ width?: string; height?: string }>`
  padding-right: 15px;
  display: block;
  ${media.lg`
    display: none;
  `}
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `}
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
`;

export const Divider = styled.div`
  border-top: 1.5px solid ${colors.lightGrey};
  padding-bottom: 15px;
  margin-top: 15px;
  ${media.lg`
    padding-top: unset;
    margin-bottom: unset;
    border-left: 1.5px solid ${colors.lightGrey};
  `}
`;

export const DividerHorizontal = styled.div`
  width: 100%;
  height: 1px;
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: ${colors.lightGrey};
`;
