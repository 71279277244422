import styled from 'styled-components';
import { media } from '@/assets/styles';

export const Wrapper = styled.div`
  padding: 0 16px;

  ${media.lg`
    padding: 0;
  `}
`;
