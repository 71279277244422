import { combineReducers } from 'redux';
import loading from './loading';
import auth from './auth';
import enrollment from './enrollment';
import sites from './sites';
import dashboard from './dashboard';
import usagePattern from './usagePattern';
import messages from './message';
import setting from './setting';
import phoneOtp from './phoneOtp';
import misc from './misc';
import userInfo from './userInfo';
import { drStore } from './dr/index';

const rootReducer = combineReducers({
  loading,
  auth,
  enrollment,
  sites,
  dr: drStore,
  dashboard,
  usagePattern,
  messages,
  setting,
  phoneOtp,
  misc,
  userInfo,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
