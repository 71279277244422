import React from 'react';
import { useTranslation } from 'react-i18next';
import Plotly from 'react-plotly.js';
import { PlotData } from 'plotly.js';
import Legend from '@/molecules/Legend';
import { htmlTableRenderer } from '@/helpers/HTMLTableHelper';
import '@/assets/styles/react-plotly.css';
import { LinkButton } from '@/designSystem/components';
import { sentEvent } from '@/helpers/GAHelper';
import PATHS from '@/types/navigationPaths';
import { useMediaQuery } from 'react-responsive';
import { sizes } from '@/assets/styles/media';
import { colors } from '@/designSystem/colors';
import { suffixForCO2Languages } from '@/helpers/Edition';
import { IDDRDetailViewViewProps } from './index.types';
import {
  Wrapper,
  Title,
  GraphContainer,
  LegendContainer,
  ChartContainer,
  GraphDescriptionContainer,
} from './index.styles';

export interface PlotProps {
  data: Partial<Plotly.Data>[];
  layout: Partial<Plotly.Layout>;
}

const DRDetailViewView = ({ items }: IDDRDetailViewViewProps) => {
  const { t, i18n } = useTranslation('common');
  const isMobileWindowSize = useMediaQuery({ maxWidth: sizes.lg });

  const typicalColor = colors.grey;
  const beforeAfterEventColor = colors.primary;
  const energyEventColor = colors.accent;

  const data: PlotProps['data'] = [
    {
      name: t(
        `screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graph.legend.typical${suffixForCO2Languages}`,
      ),
      type: 'bar',
      x: items?.typicalUsage.map(({ time }) => time) ?? [],
      y: items?.typicalUsage.map(({ usage }) => usage) ?? [],
      hovertemplate: `%{y:.2f}Wh`,
      marker: {
        color: typicalColor,
      },
    },
    {
      name: t(
        `screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graph.legend.energyEvent${suffixForCO2Languages}`,
      ),
      type: 'bar',
      x: items?.userEnergyUsage.map(({ time }) => time) ?? [],
      y: items?.userEnergyUsage.map(({ usage }) => usage) ?? [],
      hovertemplate: `%{y:.2f}Wh`,
      marker: {
        color:
          items?.userEnergyUsage.map((item) => {
            if ('inRequestPeriod' in item) {
              return item.inRequestPeriod ? energyEventColor : beforeAfterEventColor;
            }
            return beforeAfterEventColor;
          }) ?? [],
      },
    },
  ] as Plotly.Data[];

  const layout = {
    paper_bgcolor: 'transparent',
    plot_bgcolor: 'transparent',
    dragmode: false,
    margin: {
      l: 20,
      r: 20,
      b: 45,
      t: 15,
      pad: 10,
    },
    legend: {
      x: 0.4,
      y: -0.5,
    },
    yaxis: {
      fixedrange: true,
      showticklabels: true,
      showgrid: false,
      zeroline: false,
      tickformat: '.2f',
      tickfont: {
        color: 'white', // color white to hide the tick labels
      },
    },
    showlegend: false,
    bargroupgap: 0.1,
  } as Partial<Plotly.Layout>;
  const config = { displayModeBar: false, responsive: true };
  const chartStyle = { width: '100%', height: 280 };

  const graphDescriptionTableHTML = htmlTableRenderer(
    t('screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graph.title'),
    [
      t(
        `screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graph.legend.typical${suffixForCO2Languages}`,
      ),
      t(
        `screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graph.legend.energyEvent${suffixForCO2Languages}`,
      ),
    ],
    data as PlotData[],
    'kWh',
    i18n.language.toLocaleLowerCase(),
    true,
  );

  const graphDescriptionFile = new Blob([graphDescriptionTableHTML], { type: 'text/html' });
  const graphDescriptionURL = URL.createObjectURL(graphDescriptionFile);
  const handleGraphDescription = () => {
    const newWindow = window.open(graphDescriptionURL, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
    sentEvent('open_graph_description', PATHS.OPEN_GRAPH_DESCRIPTION);
  };

  const noData = !items?.typicalUsage?.length && !items?.userEnergyUsage?.length;

  return (
    <Wrapper>
      <ChartContainer>
        {noData ? (
          <Title
            dangerouslySetInnerHTML={{
              __html: t('screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graph.titleNoData'),
            }}
          />
        ) : (
          <>
            <GraphContainer>
              <Plotly data={data} layout={layout} config={config} style={chartStyle} />
            </GraphContainer>
            <GraphDescriptionContainer>
              <LinkButton
                onClick={handleGraphDescription}
                title={t('common.generalButtons.graphDescription')}
                underline={false}
                ariaLabel={t('common.generalButtons.graphDescription')}
                role="link"
                padding="12px 6px"
                fontSize={isMobileWindowSize ? '17px' : '15px'}
              />
            </GraphDescriptionContainer>
          </>
        )}
      </ChartContainer>
      <LegendContainer>
        <Legend
          dotColor={typicalColor}
          title={t(
            `screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graph.legend.typical${suffixForCO2Languages}`,
          )}
        />
        <Legend
          dotColor={beforeAfterEventColor}
          title={t('screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graph.legend.bnaEnergyEvent')}
        />
        <Legend
          dotColor={energyEventColor}
          title={t(
            `screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graph.legend.energyEvent${suffixForCO2Languages}`,
          )}
        />
      </LegendContainer>
    </Wrapper>
  );
};

export default DRDetailViewView;
