import React from 'react';
import { TabButton } from '@/molecules/Tabs/modules/TabButton';
import { colors } from '@/designSystem/colors';
import { IconTabButton } from '@/molecules/Tabs/modules/IconTabButton';
import { TabButtonType } from '@/molecules/Tabs';
import { Wrapper } from './TabItem.styles';
import { ITabItemProps } from './TabItem.types';

const TabItem = ({ selected, title, onClick, fullWidth, image, tabButtonType, padding, ariaLabel }: ITabItemProps) => {
  const tabButtonRender = () => {
    if (tabButtonType === TabButtonType.Text) {
      return (
        <TabButton
          onClick={onClick}
          title={title}
          selected={selected}
          color={colors.blackGrey}
          lineHeight="18px"
          fullWidth={fullWidth}
          image={image}
          ariaLabel={ariaLabel}
        />
      );
    }
    return (
      <IconTabButton
        onClick={onClick}
        title={title}
        image={image}
        selected={selected}
        padding={padding}
        ariaLabel={ariaLabel}
      />
    );
  };

  return (
    <Wrapper selected={selected} fullWidth={fullWidth}>
      {tabButtonRender()}
    </Wrapper>
  );
};

export default TabItem;
