/* eslint-disable */
// typescript-eslint/no-explicit-any
/* ts-ignore */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Plotly from 'react-plotly.js';
import moment from 'moment';
import { Data, Layout } from 'plotly.js';
import _ from 'lodash';
import PATHS from '@/types/navigationPaths';
import { sentEvent } from '@/helpers/GAHelper';
import { images, theme } from '@/assets/styles';
import Tabs, { TabType } from '@/molecules/Tabs';
import Dropdown from '@/molecules/Dropdown';
import { TimePeriod } from '@/types/usagePattern';
import { useWindowSize } from '@/helpers/WindowHelper';
import Img from '@/atoms/Img';
import { htmlTableRenderer } from '@/helpers/HTMLTableHelper';
import { UnitHelper } from '@/helpers/UnitHelper';
import { IconButton, LinkButton } from '@/designSystem/components/buttons';
import { colors } from '@/designSystem/colors';
import { accessibilityUsageLabel } from '@/helpers/AccessibilityHelper';
import { useMediaQuery } from 'react-responsive';
import { sizes } from '@/assets/styles/media';
import { IParsedGraph, IUsagePatternViewProps } from './index.types';
import {
  Wrapper,
  Header,
  LegendContainer,
  GraphContainer,
  Button,
  Value,
  Time,
  GraphDescriptionContainer,
  DropdownContainer,
  GraphSection,
  GraphTitle,
  GraphTitleContainer,
  ChartExtremeValueIndicatorContainer,
  ExtremeValueIndicator,
  ValueTitle,
  ValueContainer,
  MobileTitle,
  MobileWrapper,
  ValueContainerMobile,
} from './index.styles';

const UsagePatternView = ({
  items,
  tabLinkSelected,
  onTabClick,
  onRange,
  onPrevRange,
  onNextRange,
}: IUsagePatternViewProps) => {
  const { t, i18n } = useTranslation('common');
  const [width] = useWindowSize();
  const [newWidth, setNewWidth] = useState(0);
  const [widthGraphContainer, setWidthGraphContainer] = useState<any>(0);

  const { timeList, range, graph = [], highestValue, lowestValue } = items;
  const [parsedGraph, setParsedGraph] = useState<any>([]);
  const [texts, setTexts] = useState<any>([]);

  const refZoomOutButton = useRef<HTMLButtonElement>(null);
  const refZoomInButton = useRef<HTMLButtonElement>(null);
  const refGraphContainer = useRef();
  const isMobileWindowSize = useMediaQuery({ maxWidth: sizes.lg });

  const getZoom = () => {
    return ((newWidth - widthGraphContainer) / getAmount()) * 10;
  };

  const getAmount = () => {
    const endWidth = widthGraphContainer + 1000;
    return (endWidth + widthGraphContainer) / 10;
  };

  const isDisabled = (isDecrease: boolean) => {
    if (isDecrease) {
      return newWidth <= widthGraphContainer;
    }
    return newWidth >= widthGraphContainer + 4000;
  };

  const weekList = t('common.week.short', {
    returnObjects: true,
  });

  const yearList = t('common.year.short', {
    returnObjects: true,
  });

  const getTexts = (filter?: (param1: any, param2?: any) => string) => {
    if (tabLinkSelected === TimePeriod.day) {
      return Array.from(
        {
          length: 24,
        },
        (_, hour) =>
          moment()
            .startOf('day')
            .hours(hour)
            .format(filter ? filter(hour) : 'ha'),
      );
    }
    if (tabLinkSelected === TimePeriod.week) {
      return weekList;
    }
    if (tabLinkSelected === TimePeriod.month) {
      return Array.from({ length: moment(range, 'MMMM YYYY').daysInMonth() }, (value, index) => {
        const month = moment(range, 'MMMM YYYY').month() + 1;
        if (!filter) {
          return `${month}/${index + 1}`;
        }
        return filter(index, month);
      });
    }
    if (tabLinkSelected === TimePeriod.year) {
      if (!filter) {
        return yearList;
      }
      return moment.months().map((day) => filter(day));
    }
    return [];
  };
  const renderTableTickText = (newData: any) => {
    if (tabLinkSelected === TimePeriod.day) {
      const filter = (hour: any) => {
        return 'ha';
      };
      return getTexts(filter);
    }
    if (tabLinkSelected === TimePeriod.week) {
      return getTexts();
    }
    if (tabLinkSelected === TimePeriod.month) {
      const filter = (index: any, month: any) => {
        return `${month}/${index + 1}`;
      };
      return getTexts(filter);
    }
    if (tabLinkSelected === TimePeriod.year) {
      const map = (day: any) => {
        return day.toUpperCase();
      };
      return getTexts(map);
    }
    return [];
  };

  const renderTickText = (newData: any) => {
    if (tabLinkSelected === TimePeriod.day) {
      const filter = (hour: any) => {
        if (getZoom() > 30) {
          return 'ha';
        }
        if ([24, 12, 6, 3].find((h) => hour % h === 0)) {
          return 'ha';
        }
        return ' ';
      };
      return getTexts(filter);
    }
    if (tabLinkSelected === TimePeriod.week) {
      return getTexts();
    }
    if (tabLinkSelected === TimePeriod.month) {
      const filter = (index: any, month: any) => {
        if (getZoom() > 40 || index === 0 || index + 1 === newData.length) {
          return `${month}/${index + 1}`;
        }
        const days = _.range(1, newData.length);
        const divisor = getZoom() > 20 ? 2 : 5;
        if (days.filter((day) => day % divisor === 0).includes(index)) {
          return `${month}/${index + 1}`;
        }
        return ' ';
      };
      return getTexts(filter);
    }
    if (tabLinkSelected === TimePeriod.year) {
      const map = (day: any) => {
        if (getZoom() > 60) {
          return day.toUpperCase();
        }
        return day.substring(0, 3).toUpperCase();
      };
      return getTexts(map);
    }
    return [];
  };

  const renderBarGap = () => {
    if (tabLinkSelected === TimePeriod.day || tabLinkSelected === TimePeriod.month) {
      return 0.4;
    }
    return 0.5;
  };

  const data = [
    {
      type: 'bar',
      y: parsedGraph.map((val: any) => {
        return val.val;
      }),
      marker: {
        color: graph.map(() => colors.accent),
      },
      hovertemplate: '<b>%{y}kWh</b><extra></extra>',
    },
  ] as Plotly.Data[];

  const layout = (): Partial<Plotly.Layout> => {
    return {
      width: newWidth,
      bargap: renderBarGap(),
      dragmode: false,
      margin: {
        r: 10,
        b: 50,
        l: 40,
        pad: 10,
      },
      autosize: true,
      xaxis: {
        fixedrange: true,
        range: [-1, parsedGraph.length],
        ticktext: parsedGraph.map((val: IParsedGraph) => {
          return val.tickLabel;
        }),
        tickvals: parsedGraph.map((val: IParsedGraph, i: number) => {
          return i;
        }),
        color: isMobileWindowSize ? colors.grey : colors.blackGrey,
      },
      yaxis: {
        fixedrange: true,
        showgrid: false,
        constrain: 'range',
        zeroline: false,
        gridwidth: 3,
        ticktext: ['0', highestValue.toString()],
        showticklabels: true,
        color: isMobileWindowSize ? colors.grey : colors.blackGrey,
      },
      annotations: [
        {
          xref: 'paper',
          yref: 'paper',
          x: 0,
          xanchor: 'right',
          y: 1.1,
          yanchor: 'bottom',
          text: '<b>kWh</b>',
          showarrow: false,
          font: {
            family: 'Lato',
            color: isMobileWindowSize ? colors.grey : colors.text,
            size: 14,
          },
        },
      ],
    };
  };

  const onClickZoomOut = () => {
    setNewWidth((w) => w - getAmount());
    const zoom = Math.round(100 - getZoom());
    if (zoom >= 100) {
      refZoomInButton?.current?.focus();
      return;
    }
    refZoomOutButton?.current?.focus();
    sentEvent('zoomout_graph', PATHS.ZOOM_OUT_GRAPH);
  };

  const onClickZoomIn = () => {
    setNewWidth((w) => w + getAmount());
    refZoomInButton?.current?.focus();
    sentEvent('zoomin_graph', PATHS.ZOOM_IN_GRAPH);
  };

  const handleGraphDescription = () => {
    const newWindow = window.open(graphDescriptionURL, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
    sentEvent('open_graph_description', PATHS.OPEN_GRAPH_DESCRIPTION);
  };

  const makeTimeList = () => {
    if (timeList.length === 1 && timeList[0] === '') {
      return [];
    }

    return timeList.map((text: string) => ({ id: text, name: text, text }));
  };

  const weekListAccessibility: any = t('common.week.long', {
    returnObjects: true,
  });

  const graphDescriptionTableHTML = htmlTableRenderer(
    t('screens.main.usagePattern.usagePatternScreen.title'),
    parsedGraph.map((val: any) => {
      return val.tableTickLabel;
    }),
    parsedGraph.map((val: any) => {
      return val.val;
    }),
    'kWh',
    i18n.language.toLocaleLowerCase(),
    '',
    //@ts-ignore
    weekListAccessibility,
  );
  const graphDescriptionFile = new Blob([graphDescriptionTableHTML], { type: 'text/html' });
  const graphDescriptionURL = URL.createObjectURL(graphDescriptionFile);

  const currentIndex = timeList.findIndex((text: string) => text === range);
  const isSidebarVisible = width < 1000;
  const maxOverlapWidth = width < 500;

  const actualTimeList = makeTimeList();
  useEffect(() => {
    if (graph) {
      // ts-ignore
      setTexts(getTexts());
      const newTickTexts = renderTickText(graph);
      const tableTickTexts = renderTableTickText(graph);
      // @ts-ignore
      setParsedGraph(
        graph.map((value, indexGraph) => ({
          tickLabel: newTickTexts[indexGraph],
          id: indexGraph,
          val: value,
          tableTickLabel: tableTickTexts[indexGraph],
        })),
      );
    }
  }, [graph, newWidth]);

  useLayoutEffect(() => {
    if (refGraphContainer) {
      // @ts-ignore
      setWidthGraphContainer(refGraphContainer.current?.offsetWidth);
    }
  }, []);

  useEffect(() => {
    if (width || widthGraphContainer) {
      setNewWidth(widthGraphContainer);
    }
  }, [width, tabLinkSelected, widthGraphContainer]);

  return (
    <Wrapper>
      <MobileWrapper>
        <Header>
          <DropdownContainer id="dropdownContainer">
            <IconButton
              onClick={onPrevRange}
              image={{
                active: {
                  src: images.datepickerArrowLeft.image,
                  alt: images.datepickerArrowLeft.text,
                },
                disabled: {
                  src: images.datepickerArrowLeft.image,
                  alt: images.datepickerArrowLeft.text,
                },
              }}
              disabled={currentIndex === timeList.length - 1 || actualTimeList.length === 0}
              ariaLabel={t('common.generalButtons.previous')}
              padding="6px 8px"
            />
            <Dropdown
              accessibilityLabel={t('screens.main.usagePattern.usagePatternScreen.dropdownLabel')}
              items={actualTimeList}
              placeholder={t('screens.main.usagePattern.usagePatternScreen.graph.noData')}
              values={[range]}
              onChange={(name) => onRange(name[0] as string)}
              hideBoxShadow
              hideBackground
            />
            <IconButton
              onClick={onNextRange}
              image={{
                active: {
                  src: images.datepickerArrowRight.image,
                  alt: images.datepickerArrowRight.text,
                },
                disabled: {
                  src: images.datepickerArrowRight.image,
                  alt: images.datepickerArrowRight.text,
                },
              }}
              disabled={currentIndex === 0 || actualTimeList.length === 0}
              ariaLabel={t('common.generalButtons.next')}
              padding="6px 8px"
            />
          </DropdownContainer>
          <Tabs
            tabs={[
              {
                id: 0,
                title: t('screens.main.usagePattern.usagePatternScreen.graph.filter.day'),
                tabName: TimePeriod.day,
              },
              {
                id: 1,
                title: t('screens.main.usagePattern.usagePatternScreen.graph.filter.week'),
                tabName: TimePeriod.week,
              },
              {
                id: 2,
                title: t('screens.main.usagePattern.usagePatternScreen.graph.filter.month'),
                tabName: TimePeriod.month,
              },
              {
                id: 3,
                title: t('screens.main.usagePattern.usagePatternScreen.graph.filter.year'),
                tabName: TimePeriod.year,
              },
            ]}
            selected={tabLinkSelected}
            onClick={(val) => onTabClick(val as TimePeriod)}
            hideBorder
          />
          <MobileTitle>{t('screens.main.usagePattern.usagePatternScreen.title')}</MobileTitle>
        </Header>
        <GraphSection>
          <>
            <GraphTitleContainer>
              <GraphTitle>{t('screens.main.usagePattern.usagePatternScreen.graph.title')}</GraphTitle>
              {!maxOverlapWidth && parsedGraph.length >= 8 && (
                <LegendContainer>
                  <Button
                    ref={refZoomOutButton}
                    id="zoomout-graph"
                    onClick={onClickZoomOut}
                    disabled={isDisabled(true)}
                    aria-label={t('common.generalButtons.zoomout')}
                    tabIndex={0}
                  >
                    -
                  </Button>
                  <p>{`${t('screens.main.usagePattern.usagePatternScreen.graph.zoom')}: ${Math.round(
                    100 + getZoom(),
                  )}%`}</p>
                  <Button
                    ref={refZoomInButton}
                    id="zoomin-graph"
                    onClick={onClickZoomIn}
                    disabled={isDisabled(false)}
                    aria-label={t('common.generalButtons.zoomin')}
                    tabIndex={0}
                  >
                    +
                  </Button>
                </LegendContainer>
              )}
            </GraphTitleContainer>
            {/* @ts-ignore */}
            <GraphContainer ref={refGraphContainer} aria-hidden="true">
              <Plotly
                data={data}
                layout={layout()}
                config={{
                  displayModeBar: false,
                }}
                style={{
                  maxWidth: width > 1920 ? widthGraphContainer : `calc(100vw - ${isSidebarVisible ? '100' : '420'}px)`,
                  maxHeight: `400px`,
                  overflow: 'auto',
                }}
              />
            </GraphContainer>
            <GraphDescriptionContainer>
              <LinkButton
                onClick={handleGraphDescription}
                title={t('common.generalButtons.graphDescription')}
                underline={false}
                ariaLabel={t('common.generalButtons.graphDescription')}
                role="link"
                padding="12px 6px"
                fontSize={isMobileWindowSize ? '17px' : '15px'}
              />
            </GraphDescriptionContainer>
            {maxOverlapWidth && parsedGraph.length >= 8 && (
              <LegendContainer>
                <Button
                  ref={refZoomOutButton}
                  id="zoomout-graph"
                  onClick={onClickZoomOut}
                  disabled={isDisabled(true)}
                  aria-label={t('common.generalButtons.zoomout')}
                  tabIndex={0}
                >
                  -
                </Button>
                <p>{`${t('screens.main.usagePattern.usagePatternScreen.graph.zoom')}: ${Math.round(
                  100 - getZoom(),
                )}%`}</p>
                <Button
                  ref={refZoomInButton}
                  id="zoomin-graph"
                  onClick={onClickZoomIn}
                  disabled={isDisabled(false)}
                  aria-label={t('common.generalButtons.zoomin')}
                  tabIndex={0}
                >
                  +
                </Button>
              </LegendContainer>
            )}
          </>
        </GraphSection>
      </MobileWrapper>
      <ChartExtremeValueIndicatorContainer>
        <ExtremeValueIndicator>
          <ValueTitle>
            {t(`screens.main.usagePattern.usagePatternScreen.graph.legend.highest.${tabLinkSelected}`)}
          </ValueTitle>
          <ValueContainer>
            <ValueContainerMobile>
              <Img src={isMobileWindowSize ? images.arrowUpExtremeHighMobile : images.arrowUpExtremeHigh} />
              <Value
                aria-label={accessibilityUsageLabel(UnitHelper.formatEnergyValue(highestValue * 1000, 2))}
                color={theme.colors.palette.highest}
              >
                {UnitHelper.formatEnergyValue(highestValue * 1000, 2)}
              </Value>
            </ValueContainerMobile>
            <Time>{texts[parsedGraph.map((g: any) => g.val).indexOf(highestValue)]}</Time>
          </ValueContainer>
        </ExtremeValueIndicator>
        <ExtremeValueIndicator>
          <ValueTitle>
            {t(`screens.main.usagePattern.usagePatternScreen.graph.legend.lowest.${tabLinkSelected}`)}
          </ValueTitle>
          <ValueContainer>
            <ValueContainerMobile>
              <Img src={isMobileWindowSize ? images.arrowDownExtremeLowMobile : images.arrowDownExtremeLow} />
              <Value
                aria-label={accessibilityUsageLabel(UnitHelper.formatEnergyValue(lowestValue * 1000, 2))}
                color={theme.colors.palette.lowest}
              >
                {UnitHelper.formatEnergyValue(lowestValue * 1000, 2)}
              </Value>
            </ValueContainerMobile>
            <Time>{texts[parsedGraph.map((g: any) => g.val).indexOf(lowestValue)]}</Time>
          </ValueContainer>
        </ExtremeValueIndicator>
      </ChartExtremeValueIndicatorContainer>
    </Wrapper>
  );
};

export default UsagePatternView;
