import React from 'react';
import { useTranslation } from 'react-i18next';
import { EarningSummary } from '@/types/dr';
import { UnitHelper } from '@/helpers/UnitHelper';
import { Description, ValueContainer, Value, ValueTitle, Wrapper, ValueColumn } from './moneyEarnedSummary.styles';

const MoneyEarnedSummary = ({
  earningsSummary,
  disclaimer,
  disclaimerSymbol,
}: {
  earningsSummary: EarningSummary;
  disclaimer: string;
  disclaimerSymbol: string;
}) => {
  const { t } = useTranslation('common');

  const decimalDigits = 2;
  const formattedMoneyEarned = UnitHelper.formatMoneyValue(
    earningsSummary?.totalMoneyEarned || 0,
    false,
    decimalDigits,
  );
  return (
    <Wrapper>
      <ValueContainer>
        <ValueColumn tabIndex={0}>
          <Value>{earningsSummary?.numEvents || '0'}</Value>
          <ValueTitle>
            {t('screens.main.savingsEvents.savingsEventsHistoryScreen.moneyEarned.summary.event')}
          </ValueTitle>
        </ValueColumn>
        <ValueColumn tabIndex={0}>
          <Value>{formattedMoneyEarned}</Value>
          <ValueTitle>
            {`${t(
              'screens.main.savingsEvents.savingsEventsHistoryScreen.moneyEarned.summary.earnings',
            )}${disclaimerSymbol}`}
          </ValueTitle>
        </ValueColumn>
      </ValueContainer>
      <Description tabIndex={0}>{disclaimer}</Description>
    </Wrapper>
  );
};

export default MoneyEarnedSummary;
