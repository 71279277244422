import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PATHS from '@/types/navigationPaths';
import { ReqUsagePattern } from '@/apis/primary/types';
import { RootState } from '@/stores/index';
import useUsagePattern from '@/hooks/useUsagePattern';
import { useSites } from '@/hooks/index';
import { GBCStatus as GBCStatusType } from '@/types/userInfo';
import contents from '@/assets/config/appInfo';
import { useTranslation } from 'react-i18next';
import UsagePattern from '@/organisms/UsagePattern';
import GBCStatus from '@/organisms/GBCStatus';
import { Wrapper } from './index.styles';

const UsagePatternScreenContainer = () => {
  const { t } = useTranslation('common');
  const {
    userInfo: {
      data: { userInfo },
    },
    usagePattern: {
      data: { usagePatternPeriod },
    },
    sites: {
      data: { currentSite },
    },
  } = useSelector((state: RootState) => state);

  // Energy Usage - ClimateResponse®
  document.title = `${t('screens.main.usagePattern.usagePatternScreen.title')} - ${contents.name}`;

  const { fetchSites } = useSites();
  const { fetchUsagePattern } = useUsagePattern();

  useEffect(() => {
    if (userInfo.gbcStatus === GBCStatusType.completed && !currentSite) {
      fetchSites(userInfo.siteName);
    }
  }, []);

  useEffect(() => {
    if (currentSite) {
      const payload: ReqUsagePattern = {
        siteId: currentSite?.id,
        timePeriod: usagePatternPeriod,
        range: 'initial',
      };
      fetchUsagePattern(payload);
    }
  }, [currentSite?.id, usagePatternPeriod]);

  const navigate = useNavigate();
  useEffect(() => {
    if (userInfo.isDeleted === true) {
      navigate(PATHS.SCREEN_SETTING_ACCOUNT.pathname);
    }
  });

  return <Wrapper>{userInfo.gbcStatus === GBCStatusType.completed ? <UsagePattern /> : <GBCStatus />}</Wrapper>;
};

export default UsagePatternScreenContainer;
