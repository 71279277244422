/* eslint-disable class-methods-use-this */
import { DashboardTypes } from '@/types/dashboard';
import { ProgramStrategy } from '../Context/interface';

export class PGE_ELRP_A6 implements ProgramStrategy {
  getDashboardType() {
    return DashboardTypes.DASHBOARD_WITH_EARNINGS_ENERGY_HISTORY_AND_GRID;
  }

  getShouldDisplayMoneyEarned() {
    return true;
  }

  getShouldDisplayEnergyEventsTab(displayMeterData?: boolean) {
    return !!displayMeterData;
  }

  getShouldDisplayProgramInformation() {
    return true;
  }

  getShouldDisplaySavingsIdeas() {
    return true;
  }

  getShouldDisplayPortfolio() {
    return false;
  }

  getShouldDisplayReferralBlock() {
    return false;
  }

  getShouldDisplayReferral() {
    return false;
  }

  getShouldDisplayRefreshMySites() {
    return false;
  }
}
