import React from 'react';
import { useTranslation } from 'react-i18next';
import { EventSummary } from '@/types/dr';
import { AchievementLevel } from '@/types/dashboard';
import { UnitHelper } from '@/helpers/UnitHelper';
import lodash from 'lodash';
import Img from '@/atoms/Img';
import { achievementIcon } from '@/helpers/AchievementIconHelper';
import IconButton from '@/atoms/IconButton';
import { images } from '@/assets/styles';
import {
  HelpContainer,
  LevelTitle,
  LevelTitleContainer,
  Value,
  ValueContainer,
  ValueTitle,
} from './eventsSummary.styles';

const EventsSummary = ({ eventsSummary, onClickHelp }: { eventsSummary: EventSummary; onClickHelp: () => void }) => {
  const { t } = useTranslation('common');
  const level = eventsSummary?.level || AchievementLevel.noAct;

  const renderTitle = () => {
    return t(`screens.main.dashboard.dashboardScreen.achievement.modalReward.level.${lodash.camelCase(level)}.title`);
  };

  return (
    <>
      <ValueContainer tabIndex={0}>
        <Value>{eventsSummary?.numRequestsTotal || '0'}</Value>
        <ValueTitle>{t('screens.main.savingsEvents.savingsEventsHistoryScreen.reduction.summary.events')}</ValueTitle>
      </ValueContainer>
      <ValueContainer tabIndex={0}>
        <Value>{UnitHelper.formatEnergyValue(eventsSummary?.electricitySaved || 0)}</Value>
        <ValueTitle>
          {t('screens.main.savingsEvents.savingsEventsHistoryScreen.reduction.summary.energyImpact')}
        </ValueTitle>
      </ValueContainer>
      <ValueContainer tabIndex={0}>
        <Img src={achievementIcon(level)} />
        <LevelTitleContainer>
          <LevelTitle level={level}>{renderTitle()}</LevelTitle>
          {onClickHelp && (
            <HelpContainer>
              <IconButton
                icon={images.help}
                onClick={onClickHelp}
                ariaLabel={t('screens.main.dashboard.dashboardScreen.achievement.help')}
              />
            </HelpContainer>
          )}
        </LevelTitleContainer>
      </ValueContainer>
    </>
  );
};

export default EventsSummary;
