import { DrHistoryItem, EarningSummary, MoneyEarned, MoneyEarnedItem, PendingMoneyEarnedItem } from '@/types/dr';
import { seasonsUpdateEarnings } from '@/helpers/SeasonsUpdateEarnings';
import { SummaryState } from '@/types/dashboard';

// Types
//
// Reset
export const RESET_EARNINGS_HISTORY = 'dr/earningsHistory/RESET_EARNINGS_HISTORY';

// Request Earnings
export const EARNINGS_HISTORY_REQUEST = 'dr/earningsHistory/EARNINGS_REQUEST';
export const EARNINGS_HISTORY_SUCCESS = 'dr/earningsHistory/EARNINGS_SUCCESS';
export const EARNINGS_HISTORY_FAILURE = 'dr/earningsHistory/EARNINGS_FAILURE';

// Select Earnings
export const EARNING_HISTORY_SELECT_REQUEST = 'dr/earningsHistory/EARNING_SELECT_REQUEST';
export const EARNING_HISTORY_SELECT_SUCCESS = 'dr/earningsHistory/EARNING_SELECT_SUCCESS';
export const EARNING_HISTORY_SELECT_FAILURE = 'dr/earningsHistory/EARNING_SELECT_FAILURE';
export const EARNING_HISTORY_SELECT_RESET = 'dr/earningsHistory/EARNING_SELECT_RESET';

interface EarningsResetAction {
  type: typeof RESET_EARNINGS_HISTORY;
}

interface EarningsRequestAction {
  type: typeof EARNINGS_HISTORY_REQUEST;
}

interface EarningSuccessAction {
  type: typeof EARNINGS_HISTORY_SUCCESS;
  earnings: MoneyEarned;
}

interface EarningsFailureAction {
  type: typeof EARNINGS_HISTORY_FAILURE;
  errorMessage: string;
}

interface EarningSelectRequestAction {
  type: typeof EARNING_HISTORY_SELECT_REQUEST;
  earnings: MoneyEarnedItem;
}

interface EarningSelectSuccessAction {
  type: typeof EARNING_HISTORY_SELECT_SUCCESS;
  earnings: MoneyEarnedItem;
  event?: DrHistoryItem;
}

interface EarningSelectFailureAction {
  type: typeof EARNING_HISTORY_SELECT_FAILURE;
  errorMessage: string;
}

interface EarningSelectResetAction {
  type: typeof EARNING_HISTORY_SELECT_RESET;
}

type InitActionTypes =
  | EarningsResetAction
  | EarningsRequestAction
  | EarningSuccessAction
  | EarningsFailureAction
  | EarningSelectRequestAction
  | EarningSelectSuccessAction
  | EarningSelectFailureAction
  | EarningSelectResetAction;

// initial state
// This is how we store the data
interface EarningsSeason {
  summary: EarningSummary;
  history: Array<MoneyEarnedItem | PendingMoneyEarnedItem>;
}

// This is how the API send the data
export interface EarningsSeasonAPIResponse {
  summary: EarningSummary;
  history: Array<MoneyEarnedItem>;
  noEarningHistory?: Array<PendingMoneyEarnedItem>;
}

// This is how the API send the data
export interface EarningsSeasonByYearAPIResponse {
  [year: string]: EarningsSeasonAPIResponse;
}

export interface EarningsHistorySeasons {
  [year: string]: EarningsSeason;
}

export interface EarningsHistoryState {
  data: {
    seasons: EarningsHistorySeasons;
    currentSeason: EarningsSeason;
    selectedEarnings?: MoneyEarnedItem;
    selectedEarningsEvent?: DrHistoryItem;
  };
  isLoading: boolean;
  isSelectingEarning: boolean;
  error: {
    hasError: boolean;
    errorMessage: string;
  };
}

const initialEarningsState = {
  seasons: {},
  currentSeason: {
    summary: {
      totalMoneyEarned: 0,
      eventMoneyEarned: 0,
      otherMoneyEarned: 0,
      numEvents: 0,
      summaryState: SummaryState.none,
    },
    history: [],
  },
  selectedEarnings: undefined,
  selectedEarningsEvent: undefined,
};

const initialState: EarningsHistoryState = {
  data: initialEarningsState,
  isLoading: false,
  isSelectingEarning: false,
  error: {
    hasError: false,
    errorMessage: '',
  },
};

// Reducer
//
// eslint-disable-next-line default-param-last
export default (state: EarningsHistoryState = initialState, action: InitActionTypes): EarningsHistoryState => {
  switch (action.type) {
    case RESET_EARNINGS_HISTORY:
      return {
        ...initialState,
      };

    case EARNINGS_HISTORY_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case EARNINGS_HISTORY_SUCCESS: {
      const seasons = seasonsUpdateEarnings(state.data.seasons, action.earnings);
      const seasonsYears = Object.keys(seasons);
      const currentSeasonYear = seasonsYears[seasonsYears.length - 1];

      return {
        ...state,
        data: {
          selectedEarnings: undefined,
          selectedEarningsEvent: undefined,
          seasons,
          currentSeason: action.earnings[currentSeasonYear],
        },
        isLoading: false,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };
    }

    case EARNINGS_HISTORY_FAILURE:
      return {
        ...state,
        data: initialEarningsState,
        isLoading: false,
        error: {
          hasError: false,
          errorMessage: action.errorMessage,
        },
      };

    case EARNING_HISTORY_SELECT_REQUEST:
      return {
        ...state,
        isSelectingEarning: true,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case EARNING_HISTORY_SELECT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          selectedEarnings: action.earnings,
          selectedEarningsEvent: action.event,
        },
        isSelectingEarning: false,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case EARNING_HISTORY_SELECT_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          selectedEarnings: undefined,
        },
        isSelectingEarning: false,
        error: {
          hasError: true,
          errorMessage: action.errorMessage,
        },
      };

    case EARNING_HISTORY_SELECT_RESET:
      return {
        ...state,
        data: {
          ...state.data,
          selectedEarnings: undefined,
        },
      };

    default:
      return state;
  }
};

// Actions
//

export const earningsHistoryRequest = (siteId: string) => ({ type: EARNINGS_HISTORY_REQUEST, siteId });

export const earningsHistorySelectRequest = (siteId: string, earnings: MoneyEarnedItem, name: string) => ({
  type: EARNING_HISTORY_SELECT_REQUEST,
  siteId,
  earnings,
  name,
});

export const earningsHistorySelectReset = () => ({ type: EARNING_HISTORY_SELECT_RESET });
