import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from '@/stores/index';
import { PerformanceToDateEnum } from '@/types/dr';
import { sentEvent } from '@/helpers/GAHelper';
import PATHS from '@/types/navigationPaths';
import Tabs from '@/molecules/Tabs';
import { getProgramContext } from '@/helpers/ProgramStrategy';
import { ProgramName } from '@/types/enrollment';
import ModalReward from '@/organisms/ModalReward';
import { FillPrimary } from '@/designSystem/components/buttons';
import { useMediaQuery } from 'react-responsive';
import { sizes } from '@/assets/styles/media';
import { useNavigate } from 'react-router-dom';
import DataOnlyMessage from '@/molecules/DataOnlyMessage';
import { getProgramNameFromSite } from '@/helpers/GetProgramName';
import { useHistoryScreen } from '@/hooks/index';
import { billCreditPrograms, moneyEarnedDisclaimer } from '@/helpers/MoneyEarnedDisclaimer';
import { suffixForCO2Languages } from '@/helpers/Edition';
import { ISavingsEventsHistoryContainerProps } from './index.types';
import {
  Header,
  Body,
  UsageGroup,
  Wrapper,
  Section,
  TitleContainer,
  Title,
  InnerSection,
  EventListContainer,
  EventHistoryButtonContainer,
  styleButton,
} from './index.styles';
import EventList from '../EventList';
import MoneyEarnedList from '../MoneyEarnedList';
import { MoneyEarnedSummary } from '../MoneyEarnedSummary';
import { EventsSummary } from '../EventsSummary';
import SeasonSelector from '../SeasonSelector/seasonSelector';

const SavingsEventsHistoryContainer = ({
  performanceToDateRef,
  setSelectedSeasonIndex,
  selectedSeasonIndex,
  seasonYears,
  selectedSeasonYear,
  eventsSeasons,
  earningsSeasons,
  children,
}: ISavingsEventsHistoryContainerProps) => {
  const {
    dr: {
      historyScreen: { selectedTab },
    },
    sites: {
      data: { currentSite },
    },
  } = useSelector((state: RootState) => state);
  const { t } = useTranslation('common');
  const { fetchTabSelect } = useHistoryScreen();
  const [shouldDisplayRewardModal, setShouldDisplayRewardModal] = useState(false);
  const isMobileWindowSize = useMediaQuery({ maxWidth: sizes.lg });
  const navigate = useNavigate();

  const handleTab = (link?: string) => {
    sentEvent('click', PATHS.TAB_LINK(link || ''));

    fetchTabSelect(link as PerformanceToDateEnum);
    if (performanceToDateRef && performanceToDateRef?.current) {
      performanceToDateRef?.current?.focus();
    }
  };

  const handleHelp = () => {
    sentEvent('view_achievement');
    sentEvent('click', PATHS.HELP_ACHIEVEMENT);
    setShouldDisplayRewardModal(true);
  };

  const isLastSeasonSelected = selectedSeasonYear === seasonYears[0];
  const isCurrentSeasonSelected = selectedSeasonYear === seasonYears[seasonYears.length - 1];

  const selectedEventsSeason = eventsSeasons[selectedSeasonYear];
  const initialSeasonalEarnings = {
    summary: {
      eventMoneyEarned: 0,
      otherMoneyEarned: 0,
      totalMoneyEarned: 0,
      numEvents: 0,
      summaryState: 'none',
    },
    history: [],
  };
  const selectedEarningsSeason = earningsSeasons[selectedSeasonYear] || initialSeasonalEarnings;

  const programName = getProgramNameFromSite(currentSite);
  const hasEvents = selectedEventsSeason?.history?.length > 0;
  const moneyEarnedInProgram = currentSite?.program?.attributes?.moneyEarnedInProgram;

  const hasEarnings = selectedEarningsSeason?.history?.length > 0;
  if (hasEvents && !hasEarnings) {
    selectedEarningsSeason.summary.numEvents = selectedEventsSeason?.summary.numRequestsTotal;
  }

  const context = getProgramContext({ programName });
  const shouldDisplayMoneyEarned = context?.getShouldDisplayMoneyEarned(hasEvents);

  const moneyEarnedPerEvent = currentSite?.program?.attributes?.moneyEarnedPerEvent;

  const { disclaimerNamespace, symbol } = moneyEarnedDisclaimer({
    moneyEarnedPerEvent,
    summaryState: selectedEarningsSeason?.summary?.summaryState,
    isSceWithEvents: billCreditPrograms.includes(programName) && hasEvents,
  });

  const moneyEarnedDisclaimerText = t(disclaimerNamespace);

  const handleViewEventsHistory = () => {
    navigate(PATHS.SCREEN_MOBILE_EVENT.pathname);
  };

  return (
    // Performance to Date
    <Wrapper>
      <Section>
        <TitleContainer>
          <Title>{t(`screens.main.savingsEvents.savingsEventsHistoryScreen.${selectedTab}.summary.title`)}</Title>
        </TitleContainer>
        {programName === ProgramName.DATA_ONLY ? (
          <DataOnlyMessage />
        ) : (
          <UsageGroup>
            {selectedTab === PerformanceToDateEnum.reduction ? (
              <EventsSummary eventsSummary={selectedEventsSeason?.summary} onClickHelp={handleHelp} />
            ) : (
              <MoneyEarnedSummary
                earningsSummary={selectedEarningsSeason?.summary}
                disclaimer={moneyEarnedDisclaimerText}
                disclaimerSymbol={symbol}
              />
            )}
          </UsageGroup>
        )}

        {isMobileWindowSize && programName !== ProgramName.DATA_ONLY && (
          <EventHistoryButtonContainer>
            <FillPrimary
              title={t(`screens.main.savingsEvents.savingsEventsHistoryScreen.viewEventsHistory`)}
              onClick={handleViewEventsHistory}
              padding={styleButton.padding}
            />
          </EventHistoryButtonContainer>
        )}
      </Section>
      {programName === ProgramName.DATA_ONLY && (
        <Section>
          <TitleContainer>
            <Title>
              {t('screens.main.savingsEvents.portfolioScreen.eventsHistory')} {selectedSeasonYear}
            </Title>
          </TitleContainer>
          <DataOnlyMessage />
        </Section>
      )}
      {!isMobileWindowSize && programName !== ProgramName.DATA_ONLY && (
        <Section>
          <TitleContainer>
            <Title>
              {t('screens.main.savingsEvents.portfolioScreen.eventsHistory')} {selectedSeasonYear}
            </Title>
            <SeasonSelector
              selectedSeason={selectedSeasonYear}
              onPressArrowLeft={() => setSelectedSeasonIndex(selectedSeasonIndex - 1)}
              onPressArrowRight={() => setSelectedSeasonIndex(selectedSeasonIndex + 1)}
              isLastSeasonSelected={isLastSeasonSelected}
              isCurrentSeasonSelected={isCurrentSeasonSelected}
            />
          </TitleContainer>
          <InnerSection>
            <EventListContainer>
              <Header>
                <Tabs
                  fullWidth
                  tabs={
                    moneyEarnedInProgram && shouldDisplayMoneyEarned
                      ? [
                          {
                            id: 0,
                            title: t(
                              `screens.main.savingsEvents.savingsEventsHistoryScreen.reduction.title${suffixForCO2Languages}`,
                            ),
                            tabName: PerformanceToDateEnum.reduction,
                          },
                          {
                            id: 1,
                            title: t('screens.main.savingsEvents.savingsEventsHistoryScreen.moneyEarned.title'),
                            tabName: PerformanceToDateEnum.moneyEarned,
                          },
                        ]
                      : [
                          {
                            id: 0,
                            title: t(
                              `screens.main.savingsEvents.savingsEventsHistoryScreen.reduction.title${suffixForCO2Languages}`,
                            ),
                            tabName: PerformanceToDateEnum.reduction,
                          },
                        ]
                  }
                  selected={selectedTab}
                  onClick={handleTab}
                />
              </Header>
              <Body>
                {selectedTab === PerformanceToDateEnum.reduction ? (
                  <EventList eventsHistory={selectedEventsSeason?.history || []} />
                ) : (
                  <MoneyEarnedList earningsHistory={selectedEarningsSeason?.history || []} />
                )}
              </Body>
            </EventListContainer>
            {children}
          </InnerSection>
        </Section>
      )}

      <ModalReward show={shouldDisplayRewardModal} onClose={() => setShouldDisplayRewardModal(false)} />
    </Wrapper>
  );
};

export default SavingsEventsHistoryContainer;
