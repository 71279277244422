import styled, { css } from 'styled-components';
import { helpers, media } from '@/assets/styles';
import { colors, typography } from '@/designSystem/index';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  margin-top: 25px;
  background-color: ${colors.background};
  border-radius: 8px;
  padding: 15px 10px;
  ${media.lg`
    display: unset;
    margin-top: unset;
    background-color: unset;
    padding: unset;
  `}
`;

export const UsageWrapper = styled.div<{ moneyEarnedInProgram?: boolean }>(
  ({ moneyEarnedInProgram }) => css`
    ${helpers.flexSet('row', moneyEarnedInProgram ? 'space-between' : 'center', 'center')};
    gap: 20px;
  `,
);

export const UsageContainer = styled.div`
  padding: 9px 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: ${colors.white};
  flex: 1;
`;

export const Value = styled.p(
  ({ color }) => css`
    font-size: 22px;
    color: #47851e;
    font-weight: bold;
    margin-top: 4px;
    margin-bottom: 4px;
    font-family: 'Lato';
    text-align: center;
    color: ${color};
  `,
);

export const Description = styled.p`
  font-family: 'Lato';
  font-size: 16px;
  text-align: center;
  color: ${colors.blackGrey};
`;

export const ChartContainer = styled.div`
  padding-top: 30px;
  flex: 1;
`;

export const GraphContainer = styled.div`
  flex: 1;
`;

export const LegendContainer = styled.div`
  ${helpers.flexSet('column')};
  margin: 20px auto;
`;

export const GraphDescriptionContainer = styled.div`
  display: flex;
  justify-content: right;
  padding: 10px 0;
`;

export const GraphDescription = styled.a(
  ({ theme }) => css`
    ${helpers.colorSet({ color: theme.colors.link.default })};
    cursor: pointer;
  `,
);

export const Disclaimer = styled.span`
  ${typography.xxs};
  display: block;
  font-weight: 400;
  line-height: 18px;
  color: ${colors.grey};
  text-align: center;
  align-self: center;
  margin-top: 8px;
`;
